<template>
    <div class="Article" v-if="show" :style="form > 2 ? 'background-color: #F9F9F9': ''">
	    <CircleUser v-if="form > 2" :userData="data.user"></CircleUser>
        <!-- 标题 -->
        <div class="title">
	        <span v-if="data.topicId!=0" style="color:#2970ff;margin-top: 10px" @click="openTopic(data.topic)">#{{data.topic}}#</span>
          <span class="t" style="color: #1C7EFF; margin-right: 4px" @click="openInfo(item.uuid, item.userType)" v-for="(item, index) in data.aT" :key="index">@{{item.nickName}}</span>
            <span class="t" @click="content_info">{{data.title}}</span>
        </div>

        <!-- 内容 -->
        <div class="main" @click="content_info">
            <img  :src="cover+'?x-oss-process=image/resize,m_fill,h_422,w_350'" class="thumb">

            <div class="content" style="overflow: hidden;">
                <span v-html="data.content"></span>
            </div>
        </div>
	    <div v-if="form > 2">
            <div style="display: flex;">
                <div class="volume" style="margin-right: 20px">{{data.updateTime}}</div>
                <div class="volume">阅读量:{{data.clickCount}}</div>
            </div>
            <DynamicOperation :type="2" :data="data" @getForwardContent="getForwardContent" style="line-height: 30px"></DynamicOperation>
	    </div>
    </div>

</template>

<script>
    import CircleUser from "@/pages/circle/components/circleUser.vue";
    import DynamicOperation from "./DynamicOperation";
    export default {
        name:"Article",
        components: {
            DynamicOperation,
	        CircleUser
        },
        computed: {
            user() {
                return this.$store.state.user
            },
            is_login(){
                return this.$store.state.is_login
            },
        },
        data () {
            return {
                //是否展示
                show:true,
                cover:""
            }
        },
        props:{
            data:{
                type:Object,
                default(){
                    return {
                        videos:[],
                        content:"",
                    }
                }
            },
	        form:{
		        type:Number,
		        default() {
			        return 1;
		        }
	        },
        },
        created:function(){

        },
        mounted: function () {
            this.cover = this.data.cover;
        },
        methods:{
          // 点击打开用户信息
          openInfo(uuid, userType) {
            var that = this;
            if (!this.utils.toAuth(1)) {
              return false
            }
            if (userType === 1) {
              this.utils.a('/circle/mySpace/' + uuid)
            }
            if (userType === 2 || userType === 3) {
              this.utils.a('/circle/organSpace/' + uuid)
            }
          },
            getForwardContent() {
                this.$emit('getForwardContent');
            },
	        //打开话题列表
	        openTopic(name){
            if (!this.utils.toAuth(0)) {
              return false
            }
		        this.utils.b('/topic/info/'+name)
	        },
            //跳转至详情页
            content_info(){
              if (!this.utils.toAuth(0)) {
                return false
              }
                if (this.data.bgbType == 2) {
                    this.utils.b('/content/info/' + this.data.id + "/1");
                }
                if (this.data.bgbType == 6) {
                    this.utils.b('/content/info/' + this.data.id + "/2");
                }
            },
            //下拉菜单触发的方法
            more_action:function(e){
                if (this.is_login == 0) {
                    this.err("请先登录");
                    return;
                }
                var that = this;
                if (e == "collection_user") {
                    this.collection_user();
                }
                if (e == "edit") {
                    this.utils.b('/content/edit/' + this.data.id);
                }
                if (e == "delete") {
                    that.$confirm('删除后无法恢复，确认删除吗？')
                        .then(_ => {
                            console.log(_);
                            that.newApi.InspirationDelete({id:this.data.id,bgbType:2}).then((res) => {
                                if(res.isSuccess == 1){
                                    that.utils.sus(res.data);
                                    that.show = false;
                                    this.$EventBus.$emit("changeData", {changeData: 1});
                                }
                            })
                        })
                        .catch(_ => {
                            console.log(_);
                        });
                }
            }

        }
    }

</script>

<style scoped>
    .Article{
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 10px;
        background-color: #ffffff;
        border-radius: 5px 5px 5px 5px;
        cursor: pointer;
    }
    .Article .head{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .Article .title{
        margin-top: 10px;
        line-height: 20px;
        font-size: 15px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 6;
    }
    .Article .title .contentType{
        color: #031F88;
        height: 16px;
        font-weight: 600;
        margin-right: 10px;
    }
    .Article .title .t{
        color: #555555;
    }
    .Article .main{
        margin-top: 6px;
        height: 200px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .Article .main .thumb{
        width: 200px;
        height: 200px;
    }
    .Article .main .content{
        flex: 1;
        height: 200px;
        box-sizing: border-box;
        padding: 5px 22px;
        border: 1px solid #BDBFC1;
        border-left: none;
        align-items: center;
        color: #444444;
        font-size: 16px;
        letter-spacing: 1px;
    }
    .spacebetween{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .Article .volume{
        height: 30px;
        line-height: 30px;
        color: #999999;
        font-size: 12px;
    }
    .Article .bottom{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        line-height:50px;
        height: 50px;
        color: #444444;
        font-size: 16px;
        text-align: center;
    }
    .Article .bottom .btn{
        display: flex;
        justify-content:center;
        align-items: center;
        flex: 1;
    }
    .mr5{
        margin-right: 5px;
    }
</style>
