<template>
    <div class="hand-style" :style="form > 2 ? 'background-color: #F9F9F9': ''">
	    <CircleUser v-if="form > 2" :userData="data.user" class="hand op"></CircleUser>
        <div v-if="show">
            <!-- 内容 -->
            <div @click="content_info" class="hand op">

                <div class="substance content">
	                <span v-if="data.topicId!=0" style="color:#2970ff;margin-top: 10px" @click="openTopic(data.topic)">#{{data.topic}}#</span>
                  <span class="t" style="color: #1C7EFF; margin-right: 4px" @click="openInfo(item.uuid, item.userType)" v-for="(item, index) in data.aT" :key="index">@{{item.nickName}}</span>
                    <span class="t" style="white-space: pre-wrap;" v-html="data.content"></span>
                </div>
                <!-- 图片 -->
                <div class="imgs" v-show="data.images.length > 0" v-if="data.bgbType === 3">
                    <!-- 单图 -->
                    <div v-if="data.images.length == 1" style="width:100%;height:406px;overflow: hidden;">
                        <img :src="data.images[0].url" style="width:auto;height:100%;">
                    </div>

                    <!-- 二至四张图 -->
                    <div v-if="data.images.length >= 2 && data.images.length <= 4" style="width:100%;height:200px;display: flex;justify-content: space-between;align-items: center;">
                        <div style="width: calc((100% - 15px) / 4);height: 100%" v-for="item in 4" :key="item" >
                            <img ref="container" :src="data.images[item - 1].url+'?x-oss-process=image/resize,m_fill,h_422,w_' + width" style="width: 100%;height: 100%;" v-if="data.images.length >= item">
                        </div>
                    </div>

                    <!-- 大于四张图 -->
                    <div v-if="data.images.length > 4"  style="width:100%;height:406px;">
                        <div class="spacebetween">
                            <div style="width: 406px; height: 406px">
                                <img :src="data.images[0].url+'-1024x1024'" style="width: 100%;height: 100%;">
                            </div>
                            <div style="width:calc(100% - 411px);height: 406px; display: flex;flex-direction: column;justify-content: space-between;">
                                <div class="spacebetween" style="height: calc((100% - 5px) / 2)">
                                    <img ref="container" :src="data.images[1].url+'?x-oss-process=image/resize,m_fill,h_200,w_' + width" style="width:calc((100% - 5px) / 2);height:100%;">
                                    <img :src="data.images[2].url+'?x-oss-process=image/resize,m_fill,h_200,w_' + width" style="width:calc((100% - 5px) / 2);height:100%;">
                                </div>
                                <div class="spacebetween"  style="height: calc((100% - 5px) / 2)">
                                    <img :src="data.images[3].url+'?x-oss-process=image/resize,m_fill,h_200,w_' + width" style="width:calc((100% - 5px) / 2);height:100%;">
                                    <img :src="data.images[4].url+'?x-oss-process=image/resize,m_fill,h_200,w_' + width" style="width:calc((100% - 5px) / 2);height:100%;">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="imgs" v-show="data.images.length > 0" v-if="data.bgbType === 7">
                <!-- 单图 -->
                <div v-if="data.images.length == 1" style="width:100%;height:406px;overflow: hidden;">
                  <img :src="data.images[0].url" style="width:auto;height:100%;">
                </div>

                <!-- 二至四张图 -->
                <div v-if="data.images.length >= 2 && data.images.length <= 4" style="width:100%;height:200px;display: flex;justify-content: space-between;align-items: center;">
                  <div style="width: calc((100% - 15px) / 4);height: 100%" v-for="item in 4" :key="item" >
                    <img ref="container" :src="data.images[item - 1].url+'?x-oss-process=image/resize,m_fill,h_422,w_' + width" style="width: 100%;height: 100%;" v-if="data.images.length >= item">
                  </div>
                </div>

                <!-- 大于四张图 -->
                <div v-if="data.images.length > 4"  style="width:100%;height:406px;">
                  <div class="spacebetween">
                    <div style="width: 406px; height: 406px">
                      <img :src="data.images[0].url+'-1024x1024'" style="width: 100%;height: 100%;">
                    </div>
                    <div style="width:calc(100% - 411px);height: 406px; display: flex;flex-direction: column;justify-content: space-between;">
                      <div class="spacebetween" style="height: calc((100% - 5px) / 2)">
                        <img ref="container" :src="data.images[1].url+'?x-oss-process=image/resize,m_fill,h_422,w_' + width" style="width:calc((100% - 5px) / 2);height:100%;">
                        <img :src="data.images[2].url+'?x-oss-process=image/resize,m_fill,h_422,w_' + width" style="width:calc((100% - 5px) / 2);height:100%;">
                      </div>
                      <div class="spacebetween"  style="height: calc((100% - 5px) / 2)">
                        <img :src="data.images[3].url+'?x-oss-process=image/resize,m_fill,h_422,w_' + width" style="width:calc((100% - 5px) / 2);height:100%;">
                        <img :src="data.images[4].url+'?x-oss-process=image/resize,m_fill,h_422,w_' + width" style="width:calc((100% - 5px) / 2);height:100%;">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

        </div>
	    <div v-if="form > 2">
            <div style="display: flex;">
                <div class="volume" style="margin-right: 20px">{{data.updateTime}}</div>
                <div class="volume">阅读量:{{data.clickCount}}</div>
            </div>
            <DynamicOperation :type="2" :data="data" @getForwardContent="getForwardContent" style="line-height: 30px"></DynamicOperation>
	    </div>
    </div>


</template>

<script>
    import CircleUser from "@/pages/circle/components/circleUser.vue";
    import DynamicOperation from "./DynamicOperation";
    export default {
        name:"ImagesContent",
        components: {
            DynamicOperation,
	        CircleUser,

        },
        computed: {
            user() {
                return this.$store.state.user
            },
            is_login(){
                return this.$store.state.is_login
            },
        },
        data () {
            return {
                //是否展示
                show:true,
                edit_show:false,
                width: '',
            }
        },
        props:{
            data:{
                type:Object,
                default(){
                    return {
                        videos:[],
                        content:"",
                    }
                }
            },
	        form:{
		        type:Number,
		        default() {
			        return 1;
		        }
	        }
        },
        created:function(){

        },
        mounted: function () {
          if (this.data.images.length > 4) {
            this.width = this.$refs.container.offsetWidth
          }
          if (this.data.images.length >= 2 && this.data.images.length <= 4) {
            this.width = this.$refs.container[0].offsetWidth
          }

        },
        methods:{
          // 点击打开用户信息
          openInfo(uuid, userType) {
            if (!this.utils.toAuth(1)) {
              return false
            }
            if (userType === 1) {
              this.utils.a('/circle/mySpace/' + uuid)
            }
            if (userType === 2 || userType === 3) {
              this.utils.a('/circle/organSpace/' + uuid)
            }
          },
            getForwardContent() {
                this.$emit('getForwardContent');
            },
	        //打开话题列表
	        openTopic(name){
            if (!this.utils.toAuth(0)) {
              return false
            }
		        this.utils.b('/topic/info/'+name)
	        },
            //跳转至详情页
            content_info(){
              if (!this.utils.toAuth(0)) {
                return false
              }
	            if (this.data.bgbType == 3) {
		            this.utils.b('/circle/imageInfo/' + this.data.id)
	            }
	            if (this.data.bgbType == 7) {
		            this.utils.b('/content/picture/' + this.data.id);
	            }

            },

        }
    }

</script>

<style scoped>
    .Dynamic{
        width: 100%;
        padding: 10px 38px 10px;

        margin-bottom: 10px;
        background-color: #ffffff;
        border-radius: 5px 5px 5px 5px;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
        cursor: pointer;
    }
    .Dynamic .head{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .Dynamic .substance{
        margin-top: 10px;
        line-height: 20px;
        font-size: 15px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 6;
    }
    .Dynamic .substance .contentType{
        color: #031F88;
        height: 16px;
        font-weight: 600;
        margin-right: 10px;
    }
    .Dynamic .substance .t{
        margin-right: 10px;
        color: #555555;
    }
    .Dynamic .imgs{
        margin-top: 4px;
    }
    .spacebetween{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .Dynamic .volume{
        height: 30px;
        line-height: 30px;
        color: #999999;
        font-size: 12px;
    }
    .Dynamic .bottom{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        line-height:50px;
        height: 50px;
        color: #444444;
        font-size: 16px;
        text-align: center;
    }
    .Dynamic .bottom .btn{
        display: flex;
        justify-content:center;
        align-items: center;
        flex: 1;
    }

    .Dynamic .content {
        text-overflow:ellipsis;
        -webkit-line-clamp:4;
        -webkit-box-orient:vertical;
        overflow:hidden;
        display:-webkit-box;
        margin-bottom: 6px;
    }

    .mr5{
        margin-right: 5px;
    }
</style>
