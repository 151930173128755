<template>
    <div class="VideoContent" :style="form > 2 ? 'background-color: #F9F9F9': ''">
	    <CircleUser v-if="form > 2" :userData="data.user"></CircleUser>
        <div class="VideoContent">
            <!-- 内容 -->
            <div class="substance hand op" @click="content_info">
                <div v-if="data.bgbType === 1" class="content" style="margin-bottom: 0; width: 100%;">{{data.title}}</div>
                <span class="content" style="width: 100%;">
	                <span v-if="data.topicId!=0" style="color:#2970ff;margin-top: 10px" @click="openTopic(data.topic)">#{{data.topic}}#</span>
                   <span class="t" style="color: #1C7EFF; margin-right: 4px; white-space: pre-wrap" @click="openInfo(item.uuid, item.userType)" v-for="(item, index) in data.aT" :key="index">@{{item.nickName}}</span>
                    {{data.content}}
<!--                    <span v-if="data.link" @click="toLink(data.link)" class="t" style="color: #1C7EFF; margin-right: 4px"><i class="el-icon-paperclip"></i>{{data.link}}</span>-->
                </span>
            </div>
            <div class="video" @click="setClickCount(data.id, data.bgbType)">
                <video-player  class="video-player vjs-custom-skin" id="videoPlayer" ref="videoPlayer" :playsinline="true" :options="playerOptions"></video-player>
            </div>
        </div>
	    <div v-if="form > 2">
            <div style="display: flex;">
                <div class="volume" style="margin-right: 20px">{{data.updateTime}}</div>
                <div class="volume">阅读量:{{data.clickCount}}</div>
            </div>
            <DynamicOperation :type="2" :data="data" @getForwardContent="getForwardContent" style="line-height: 30px"></DynamicOperation>
	    </div>
    </div>


</template>

<script>
    import CircleUser from "@/pages/circle/components/circleUser.vue";
    import DynamicOperation from "./DynamicOperation";
    export default {
        name:"VideoContent",
        components: {
            DynamicOperation,
	        CircleUser,
        },
        computed: {
            user() {
                return this.$store.state.user
            },
            is_login(){
                return this.$store.state.is_login
            },
        },
        data () {
            return {
                //是否展示
                show:true,
                edit_show:false,
                showAll: false,
                playerOptions: {
                    playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                    autoplay: false, // 如果为true,浏览器准备好时开始回放。
                    muted: false, // 默认情况下将会消除任何音频。
                    loop: false, // 是否视频一结束就重新开始。
                    preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                    language: 'zh-CN',
                    aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    sources: [{
                        type: "video/mp4", // 类型
                        src: this.data.videos[0].url // url地址
                    }],
                    poster: this.data.videos[0].url + '?x-oss-process=video/snapshot,t_0,f_jpg', // 封面地址
                    notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                    controlBar: {
                        timeDivider: true, // 当前时间和持续时间的分隔符
                        durationDisplay: true, // 显示持续时间
                        remainingTimeDisplay: false, // 是否显示剩余时间功能
                        fullscreenToggle: true // 是否显示全屏按钮
                    }
                },
                clickViodeCount:0
            }
        },
        props:{
            data:{
                type:Object,
                default(){
                    return {
                        videos:[],
                        content:"",
                    }
                }
            },
	        form:{
		        type:Number,
		        default() {
			        return 1;
		        }
	        },
            //是否展示来自那个room
            showRoom:{
                type:Boolean,
                default(){
                    return true
                }
            }
        },
        created:function(){

        },
        mounted: function () {

        },
        methods:{
          // 点击打开用户信息
          openInfo(uuid, userType) {
            if (!this.utils.toAuth(1)) {
              return false
            }
            if (userType === 1) {
              this.utils.a('/circle/mySpace/' + uuid)
            }
            if (userType === 2 || userType === 3) {
              this.utils.a('/circle/organSpace/' + uuid)
            }
          },
            getForwardContent() {
                this.$emit('getForwardContent');
            },
	        //打开话题列表
	        openTopic(name){
            if (!this.utils.toAuth(0)) {
              return false
            }
		        this.utils.b('/topic/info/'+name)
	        },
            setClickCount(id, bgbType){
                var that = this;
                if(id > 0 && this.clickViodeCount == 0){
                    that.clickViodeCount++;
                    // eslint-disable-next-line no-unused-vars
                    that.newApi.setVideoClickCount({id:id, bgbType: bgbType}).then((res => {
                    }))
                }
                console.log(this.clickViodeCount);
            },

            //跳转至详情页
            content_info(){
              if (!this.utils.toAuth(0)) {
                return false
              }
	            if (this.data.bgbType == 4) {
		            this.utils.b('/circle/videoInfo/' + this.data.id)
	            }
	            if (this.data.bgbType == 8) {
		            this.utils.b('/content/picture/' + this.data.id);
	            }

            },

            // 点击展示全部内容
            showAllContent() {
                this.showAll = !this.showAll
            },

            //下拉菜单触发的方法
            more_action:function(e){
                if (this.is_login == 0) {
                    this.utils.err("请先登录");
                    return;
                }
                var that = this;
                if (e == "collection_user") {
                    this.collection_user();
                }
                if (e == "delete") {
                    that.$confirm('删除后无法恢复，确认删除吗？')
                        .then(_ => {
                            console.log(_);
                            this.newApi.InspirationDelete({id:that.data.id,bgbType:4}).then(ret =>{
                                if(ret.isSuccess == 1){
                                    that.utils.sus(ret.data);
                                    that.show = false;
                                    this.$EventBus.$emit("changeData", {changeData: 1});
                                }
                            })
                        })
                        .catch(_ => {
                            console.log(_);
                        });
                }
                if (e == "edit") {
                    that.show = false;
                    that.edit_show = true;
                }
            }

        }
    }

</script>

<style scoped>
    .VideoContent{
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 10px;
        border-radius: 5px 5px 5px 5px;
        cursor: pointer;
    }
    .VideoContent .head{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .VideoContent .substance{
        margin-top: 10px;
        line-height: 20px;
        font-size: 15px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .VideoContent .substance .contentType{
        color: #031F88;
        height: 16px;
        font-weight: 600;
        margin-right: 10px;
    }
    .VideoContent .substance .t{
        color: #555555;
    }
    .VideoContent .video{
        margin-top: 6px;
    }
    .spacebetween{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .VideoContent .volume{
        height: 30px;
        line-height: 30px;
        color: #999999;
        font-size: 12px;
    }
    .VideoContent .bottom{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        line-height:50px;
        height: 50px;
        color: #444444;
        font-size: 16px;
        text-align: center;
    }
    .VideoContent .bottom .btn{
        display: flex;
        justify-content:center;
        align-items: center;
        flex: 1;
    }

    .VideoContent .content {
        text-overflow:ellipsis;
        -webkit-line-clamp:4;
        -webkit-box-orient:vertical;
        overflow:hidden;
        display:-webkit-box;
        margin-bottom: 6px;
    }
    .VideoContent .contentAll {
        overflow:hidden;
        margin-bottom: 6px;
    }

    .mr5{
        margin-right: 5px;
    }

</style>
