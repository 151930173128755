<template>
    <div class="enclosure">
	    <CircleUser v-if="form > 2" :userData="data.user"></CircleUser>
        <div class="enclosure" v-if="show">
            <!-- 文件夹 -->
            <div class="title">
                <div style="display: flex">
                    <span style="margin-right: 10px" lcass="folder"><svg t="1666784999025" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2617" width="45" height="30"><path d="M855.04 385.024q19.456 2.048 38.912 10.24t33.792 23.04 21.504 37.376 2.048 54.272q-2.048 8.192-8.192 40.448t-14.336 74.24-18.432 86.528-19.456 76.288q-5.12 18.432-14.848 37.888t-25.088 35.328-36.864 26.112-51.2 10.24l-567.296 0q-21.504 0-44.544-9.216t-42.496-26.112-31.744-40.96-12.288-53.76l0-439.296q0-62.464 33.792-97.792t95.232-35.328l503.808 0q22.528 0 46.592 8.704t43.52 24.064 31.744 35.84 12.288 44.032l0 11.264-53.248 0q-40.96 0-95.744-0.512t-116.736-0.512-115.712-0.512-92.672-0.512l-47.104 0q-26.624 0-41.472 16.896t-23.04 44.544q-8.192 29.696-18.432 62.976t-18.432 61.952q-10.24 33.792-20.48 65.536-2.048 8.192-2.048 13.312 0 17.408 11.776 29.184t29.184 11.776q31.744 0 43.008-39.936l54.272-198.656q133.12 1.024 243.712 1.024l286.72 0z" p-id="2618" fill="#f4ea2a"></path></svg></span>
                    <div style="text-overflow:ellipsis;-webkit-line-clamp:4;-webkit-box-orient:vertical;overflow:hidden;display:-webkit-box;">
                        <span v-if="data.topicId!=0" style="color:#2970ff;margin-top: 10px" @click="openTopic(data.topic)">#{{data.topic}}#</span>
                      <span class="t" style="color: #1C7EFF; margin-right: 4px" @click="openInfo(item.uuid, item.userType)" v-for="(item, index) in data.aT" :key="index">@{{item.nickName}}</span>
                        <span class="t" style="white-space: pre-wrap" @click="content_info">{{data.content}}
<!--                            <span v-if="data.link" @click="toLink(data.link)" class="t" style="color: #1C7EFF; margin-right: 4px"><i class="el-icon-paperclip"></i>{{data.link}}</span>-->
                        </span>
                    </div>
                </div>

                <div class="file-box hand op" @click="downloadFiles(item.url, item.name)" v-for="(item, index) in data.enclosure" :key="index">
                    <img src="../../../assets/imgs/circle/notes.png" style="width: 22px; height: 22px; margin-right: 12px; vertical-align: middle">
                    <span style="font-size: 18px; vertical-align: middle">{{item.name}}</span>
                </div>
            </div>
        </div>
	    <div v-if="form > 2">
		    <div style="display: flex">
			    <div class="volume" style="margin-right: 20px">{{data.updateTime}}</div>
			    <div class="volume">阅读量:{{data.clickCount}}</div>
		    </div>
            <DynamicOperation :type="2" :data="data" @getForwardContent="getForwardContent" style="line-height: 30px"></DynamicOperation>
	    </div>
    </div>
</template>

<script>
    import CircleUser from "@/pages/circle/components/circleUser.vue";
    import DynamicOperation from "@/pages/circle/components/DynamicOperation.vue";
    export default {
        name: "Enclosure",
        components: {DynamicOperation, CircleUser},
        computed: {
            user() {
                return this.$store.state.user
            },
            is_login(){
                return this.$store.state.is_login
            },
        },
        data () {
            return {
                //是否展示
                edit_show:false,
                show:true,
            }
        },
        props:{
            data:{
                type:Object,
                default(){
                    return {
                        enclosure:[],
                        content:"",
                    }
                }
            },
	        form:{
		        type:Number,
		        default() {
			        return 1;
		        }
	        },
            //是否展示来自那个room
            showRoom:{
                type:Boolean,
                default(){
                    return true
                }
            }
        },
        created:function(){

        },
        mounted: function () {
        },
        methods:{
          // 点击打开用户信息
          openInfo(uuid, userType) {
            if (!this.utils.toAuth(1)) {
              return false
            }
            if (userType === 1) {
              this.utils.a('/circle/mySpace/' + uuid)
            }
            if (userType === 2 || userType === 3) {
              this.utils.a('/circle/organSpace/' + uuid)
            }
          },
            // 跳转链接
            toLink(url) {
                if (url.indexOf('http') >= 0) {
                    window.open(url, "_blank")
                } else {
                    window.open('//' + url, "_blank")
                }
            },

            getForwardContent() {
                this.$emit('getForwardContent');
            },
	        //打开话题列表
	        openTopic(name){
            if (!this.utils.toAuth(0)) {
              return false
            }
		        this.utils.b('/topic/info/'+name)
	        },
            // 下载附件
            downloadFiles(url, name) {
              if (!this.utils.toAuth(0)) {
                return false
              }
                this.utils.download(url, name)
            },

            //跳转至详情页
            content_info(){
              if (!this.utils.toAuth(0)) {
                return false
              }
            if (this.data.bgbType === 5)
                this.utils.b('/circle/enclosureInfo/' + this.data.id)
            },

            //下拉菜单触发的方法
            more_action:function(e){
                if (this.is_login == 0) {
                    this.utils.err("请先登录");
                    return;
                }
                var that = this;
                if (e == "collection_user") {
                    this.collection_user();
                }
                if (e == "delete") {
                    that.$confirm('删除后无法恢复，确认删除吗？')
                        .then(_ => {
                            console.log(_);
                            this.newApi.InspirationDelete({id:that.data.id,bgbType:5}).then(ret =>{
                                if(ret.isSuccess == 1){
                                    that.utils.sus(ret.data);
                                    that.show = false;
                                    this.$EventBus.$emit("changeData", {changeData: 1});
                                }
                            })
                        })
                        .catch(_ => {
                            console.log(_);
                        });
                }
                if (e == "edit") {
                    that.show = false;
                    that.edit_show = true;
                }
            }

        }
    }
</script>

<style scoped>
    .enclosure{
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 10px;
        background-color: #ffffff;
        border-radius: 5px 5px 5px 5px;
        cursor: pointer;
    }
    .enclosure .head{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .enclosure .title{
        overflow: hidden;
        overflow-y: scroll;
        max-height: 300px;
        margin-top: 10px;
        line-height: 26px;
        font-size: 15px;
    }

    .enclosure .title::-webkit-scrollbar {
        display: none;
    }

    .enclosure .title .contentType{
        color: #031F88;
        height: 16px;
        font-weight: 600;
        margin-right: 10px;
    }
    .enclosure .title .t{
        font-size: 15px;
        margin-right: 10px;
        color: #555555;
    }
    .enclosure .main{
        margin-top: 14px;
        height: 200px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .enclosure .main .thumb{
        width: 200px;
        height: 200px;
    }
    .enclosure .main .content{
        flex: 1;
        height: 200px;
        box-sizing: border-box;
        padding: 16px 22px;
        border: 1px solid #BDBFC1;
        border-left: none;
        display: flex;
        align-items: center;
        color: #444444;
        font-size: 16px;
        letter-spacing: 1px;
    }
    .spacebetween{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .enclosure .volume{
        height: 30px;
        line-height: 30px;
        color: #999999;
        font-size: 12px;
    }
    .enclosure .bottom{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        line-height:50px;
        height: 50px;
        color: #444444;
        font-size: 16px;
        text-align: center;
    }
    .enclosure .bottom .btn{
        display: flex;
        justify-content:center;
        align-items: center;
        flex: 1;
    }
    .mr5{
        margin-right: 5px;
    }

    .file-box {
        padding: 8px 10px;
        margin-bottom: 4px;
        background-color: #F1F1F1;
    }

    .hand{
        cursor:pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .op:active{
        opacity:0.6;
        -webkit-transition:all .1s;
        transition:all .1s;opacity:.6;
    }

    .op{
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all .5s;
        transition: all .5s;
    }
    .sc:active{
        transform:scale(0.97);
    }
</style>
