<template>
    <div class="Dynamic">
      <inputbox v-if="edit_show" :data="data"></inputbox>
	    <div class="head">
		    <div>
			    <CircleUser :userData="data.user"></CircleUser>
		    </div>
		    <div class="action hand" v-if="data.form !== 4">
			    <el-dropdown @command="more_action" v-if="me.uuid == data.user.uuid">
					<span class="el-dropdown-link">
						<i class="el-icon-caret-bottom" style="font-size: 24px"></i>
					</span>
				    <el-dropdown-menu slot="dropdown">
						<el-dropdown-item v-if="me.uuid == data.user.uuid" command="edit">编辑内容</el-dropdown-item>
					    <el-dropdown-item v-if="me.uuid == data.user.uuid"  command="delete" >删除该条内容</el-dropdown-item>
				    </el-dropdown-menu>
			    </el-dropdown>
		    </div>
	    </div>
	    <div style="width: 100%;height: auto;padding: 10px 0" v-if="data.form==3 && dataInfo.user!='undefined'">
		    <span class="t" style="color: #1C7EFF; margin-right: 4px" @click="openInfo(item.uuid, item.userType)" v-for="(item, index) in data.aT" :key="index">@{{item.nickName}}</span>{{data.forwardComment}}<span v-if="data.topicId!=0" style="color:#2970ff;" @click="openTopic(data.topic)">#{{data.topic}}#</span>
	    </div>
	    <Dynamic :form="data.form" :class="{ Dynamic: data.form === 3 }" v-if="dynamic === true" :data="dataInfo"></Dynamic>
        <ImagesContent :form="data.form" :class="{ Dynamic: data.form === 3 }" v-if ="images == true" :data="dataInfo"></ImagesContent>
        <VideoContent :form="data.form" :class="{ Dynamic: data.form === 3 }" v-if ="videos == true" :data="dataInfo"></VideoContent>
        <Enclosure :form="data.form" :class="{ Dynamic: data.form === 3 }" v-if="enclosure == true" :data="dataInfo"></Enclosure>
        <Article :form="data.form" :class="{ Dynamic: data.form === 3 }" v-if ="article == true" :data="dataInfo"></Article>
	    <div class="spacebetween" style="border-bottom: 1px solid #999999;">
		    <div class="volume">{{data.updateTime}}</div>
        <div style="display: flex;">
          <div v-if="user.uuid === data.user.uuid" class="volume" style="margin-right: 20px">
            <span v-if="data.auth === 4">全部可见</span>
            <span v-if="data.auth === 3">粉丝可见</span>
            <span v-if="data.auth === 2">好有可见</span>
            <span v-if="data.auth === 1">自己可见</span>
          </div>
          <div class="volume">阅读量:{{data.clickCount}}</div>
        </div>

	    </div>
	    <DynamicOperation :data="data" @getForwardContent="getForwardContent"></DynamicOperation>
    </div>

</template>

<script>
    import Dynamic from './Dynamic'
    import Article from './Article'
    import VideoContent from "./VideoContent";
    import ImagesContent from "./ImagesContent";
    import Enclosure from "./Enclosure";
    import CircleUser from "@/pages/circle/components/circleUser.vue";
    import DynamicOperation from "@/pages/circle/components/DynamicOperation.vue";
    import Inputbox from "@/pages/circle/components/InputBox.vue";
    export default {
        name:"ContentALL",
        components: {
          	Inputbox,
	        DynamicOperation,
	        CircleUser,
            Enclosure,
            ImagesContent,
            VideoContent,
            Dynamic,Article
        },
        data () {
            return {
                dynamic:false,
                videos:false,
                article:false,
                enclosure:false,
                images:false,
	            dataInfo: { },
              	edit_show: false
            }
        },
        props:{
            data:{
                type:Object,
                default(){
                    return {}
                }
            },

            index: {
              type: Number,
              default() {
                return undefined;
              }
            }
        },
	    computed: {
		    me() {
			    return this.$store.state.user
		    },
		    user() {
			    return this.$store.state.user
		    },
		    is_login(){
			    return this.$store.state.is_login
		    },
	    },
        created:function(){

        },
        mounted: function () {
            this.checkDataType();
        },
        methods:{
			//打开话题列表
	        openTopic(name){
	            this.utils.b('/topic/info/'+name)
	        },

	        // 点击打开用户信息
	        openInfo(uuid, userType) {
            if (!this.utils.toAuth(1)) {
              return false
            }
		        if (userType === 1) {
			        this.$router.push({ path: '/circle/mySpace/' + uuid})
		        }
		        if (userType === 2 || userType === 3) {
			        this.$router.push({ path: '/circle/organSpace/' + uuid})
		        }
	        },
	        getForwardContent() {
		        this.$emit('getForwardContent');
	        },
            checkDataType() {
                var that = this;
				if (that.data.form!=3){
					that.dataInfo = that.data;
				}else {
					that.dataInfo = that.data.sourceContent;
				}

                //1动态2笔记3图片4视频5附件
                switch (that.data.bgbType)
                {
                    case 1 :that.dynamic = true;
                        break;
                    case 10 :that.dynamic = true;
                      break;
                    case 2 : case 6:that.article = true;
                        break;
                    case 3 : case 7 :that.images = true;
                        break;
                    case 4 : case 8 :that.videos = true;
                        break;
                    case 5 : case 9 :that.enclosure = true;
                        break;
                }
            },
	        //下拉菜单触发的方法
	        more_action:function(e){
		        if (this.is_login == 0) {
			        this.utils.err("请先登录");
			        return;
		        }
		        var that = this;
		        if (e == "delete") {
			        that.$confirm('删除后无法恢复，确认删除吗？')
				        .then(_ => {
					        console.log(_);
                  if (that.data.bgbType > 5 && that.data.bgbType < 10) {
                    this.newApi.delKnowledge({id:that.data.id,type: that.data.bgbType}).then(ret =>{
                      if(ret.isSuccess == 1){
                        that.utils.sus(ret.data);
                        that.show = false;
                        that.$emit("deleteContent", that.index);
                      }
                    })
                  } else {
                    this.newApi.InspirationDelete({id:that.data.id,bgbType: that.data.bgbType}).then(ret =>{
                      if(ret.isSuccess == 1){
                        that.utils.sus(ret.data);
                        that.show = false;
                        that.$emit("deleteContent", that.index);
                      }
                    })
                  }
				        })
				        .catch(_ => {
					        console.log(_);
				        });
		        }

		        if (e == "edit") {
				  if (that.data.bgbType !== 2 && that.data.bgbType !== 6) {
						that.edit_show = true;
				  } else {
						that.utils.a('/content/add/' + that.data.id)
				  }
		        }
	        }
        }
    }

</script>

<style scoped>
.Dynamic{
	width: 100%;
	padding: 10px 38px 10px;
	box-sizing: border-box;
	margin-bottom: 10px;
	background-color: #ffffff;
	border-radius: 5px 5px 5px 5px;
	box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.1600);
}
.head{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.spacebetween{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.Dynamic .volume{
	height: 30px;
	line-height: 30px;
	color: #999999;
	font-size: 12px;
}
.Dynamic .bottom{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	line-height:50px;
	height: 50px;
	color: #444444;
	font-size: 16px;
	text-align: center;
}
.Dynamic .bottom .btn{
	display: flex;
	justify-content:center;
	align-items: center;
	flex: 1;
}
.Dynamic .content {
	text-overflow:ellipsis;
	-webkit-line-clamp:4;
	-webkit-box-orient:vertical;
	overflow:hidden;
	display:-webkit-box;
	margin-bottom: 6px;
}
.Dynamic .content::before{
	content: '';
	float: right;
	width: 0;
	height: 78px;
}
.Dynamic .contentAll {
	overflow:hidden;
	margin-bottom: 12px;
}
.mr5{
	margin-right: 5px;
}
</style>
