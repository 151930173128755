<template>
    <div class="circle-user">
        <div class="head-one hand op" @click="openInfo(userData.uuid, userData.userType)">
            <img :src="userData.headIco + '-200x200'" class="head-ico" v-if="type !== 3">
            <div class="major">
                <div class="userName">
                    <div>{{userData.nickName}}</div>
                    <div v-if="type !== 2">
                        <img v-show="userData.isVip == 1" src="../../../assets/imgs/circle/newVip.png" style="width: 24px;height: 20px;margin-left: 6px;margin-bottom: 4px;vertical-align: middle"/>
                        <img v-show="userData.v == 1" src="../../../assets/imgs/circle/newV.png" style="width: 20px;height: 20px;margin-left: 6px;margin-bottom: 2px;vertical-align: middle"/>
                        <img v-show="userData.authenticationStatus >= 2" src="../../../assets/imgs/circle/newP.png" style="width: 48px;height: 22px ; margin-left: 6px;  vertical-align: middle"/>
                        <img v-show="userData.expertAuthStatus === 2" src="../../../assets/imgs/circle/newS.png" style="width: 48px;height: 22px;margin-left: 6px;vertical-align: middle"/>
                    </div>
                </div>
              <div v-if="type !== 3 && userData.address" class="userMajor"><span v-if="userData.address.cityName">{{userData.address.cityName[1]}}</span><span v-if="userData.major && userData.address.cityName"> | </span>{{userData.major}}</div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "circleUser",
    components: {},
    data() {
        return {}
    },
    computed: {
       user() {
            return this.$store.state.user
        }
    },
    props: {
        userData: {
            type: Object,
            default() {
                return {}
            }
        },
        type: {
            type: Number,
            default() {
                return 1
            }
        },
    },
    created: function () {

    },
    mounted: function () {
    },
    methods: {
        // 点击打开用户信息
        openInfo(uuid, userType) {
          if (!this.utils.toAuth(1)) {
            return false
          }
            if (userType === 1) {
                this.utils.a('/circle/mySpace/' + uuid)
            }
            if (userType === 2 || userType === 3) {
                this.utils.a('/circle/organSpace/' + uuid)
            }
        },
    }
}
</script>

<style scoped>
    .circle-user {
        width: 100%;
    }

    .circle-user .head-one {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .head-one .head-ico {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 10px;
    }

    .head-one .userName{
        font-weight: 600;
        color: #444444;
        font-size: 16px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .head-one .userMajor{
        font-size: 13px;
        color: #999999;
        margin-top: 6px;
    }
</style>
