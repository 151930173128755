<template>
    <div :style="form==3 ? 'background-color: #F9F9F9': ''">
	    <CircleUser v-if="form==3" :userData="data.user"></CircleUser>
        <div  v-if="data.type == 1">
            <!-- 内容 -->
            <div class="substance">
                <div v-if="data.bgbType === 1" style="margin-bottom: 0; width: 100%;">{{data.title}}</div>
                <span :class="showAll === data.content.length > 200 ? 'contentAll' : 'content'" style="width: 100%;">
	                  <span v-if="data.topicId" style="color:#2970ff;" @click="openTopic(data.topic)">#{{data.topic}}#</span>
                    <span class="t" style="color: #1C7EFF; margin-right: 4px" @click="openInfo(item.uuid, item.userType)" v-for="(item, index) in data.aT" :key="index">@{{item.nickName}}</span>
                    <span v-if="showAll === data.content.length < 200" class="hand op sc" @click="showAllContent(data.id)" style="float: right; clear: both; color: #2970FF">查看全部</span>
                  <span v-if="data.bgbType === 10">
                    发布了<{{data.content}}>活动，<span style="color: #2970FF; font-weight: 600" @click="content_info">点击查看详情</span>
                  </span>
                  <span v-else>
                    <span style="white-space: pre-wrap" :style="{color: data.link ? '#031F88': '', textDecoration: data.link ? 'underline': ''}"  @click="content_info">{{data.content}}</span>
                  </span>

                    <span v-if="showAll === true" class="hand op sc" @click="showAllContent" style="color: #2970FF;">收起</span>
                </span>
            </div>
        </div>
	    <div v-if="form==3">
		    <div style="display: flex;">
			    <div class="volume" style="margin-right: 20px">{{data.updateTime}}</div>
<!--			    <div class="volume">阅读量:{{data.clickCount}}</div>-->
		    </div>
        <DynamicOperation :type="2" :data="data" @getForwardContent="getForwardContent" style="line-height: 30px"></DynamicOperation>
	    </div>
    </div>


</template>

<script>
    import CircleUser from "@/pages/circle/components/circleUser.vue";
    import DynamicOperation from "./DynamicOperation";
    export default {
        name:"Dynamic",
        components: {
            DynamicOperation,
	        CircleUser,
        },
        computed: {
            me() {
                return this.$store.state.user
            },
            is_login(){
                return this.$store.state.is_login
            },
        },
        data () {
            return {
                //是否展示
                show:true,
                edit_show:false,
                showAll: false,
                clickDynamicCount:0,
            }
        },
	    props:{
            data:{
                type:Object,
                default(){
                    return {
                        videos:[],
                        content:"",
                    }
                }
            },
		    form:{
				type:Number,
			    default() {
				    return 1;
			    }
		    }
        },
        created:function(){

        },
        mounted: function () {

        },
        methods:{
            // 点击打开用户信息
            openInfo(uuid, userType) {
              var that = this;
              if (!this.utils.toAuth(1)) {
                return false
              }
              if (userType === 1) {
                this.utils.a('/circle/mySpace/' + uuid)
              }
              if (userType === 2 || userType === 3) {
                this.utils.a('/circle/organSpace/' + uuid)
              }
            },

            getForwardContent() {
                this.$emit('getForwardContent');
            },
	        //打开话题列表
	        openTopic(name){
            if (!this.utils.toAuth(0)) {
              return false
            }
		        this.utils.b('/topic/info/'+name)
	        },
            // 跳转链接
            toLink(url) {
                if (url.indexOf('http') >= 0) {
                    window.open(url, "_blank")
                } else {
                    window.open('//' + url, "_blank")
                }
            },

            //跳转至详情页
            content_info(){
              if (!this.utils.toAuth(0)) {
                return false
              }
              if (this.data.link) {
                if (this.data.form == 4) {
                  this.$router.push({path: '/circle/index', query: {show_name: 'activity', activeId: this.data.link}});
                } else {
                  this.toLink(this.data.link)
                }
              } else {
                this.utils.b('/circle/dynamicInfo/' + this.data.id)
              }
            },

            // 点击展示全部内容
            showAllContent(id) {
                var that = this;
                this.showAll = ! this.showAll;
                if(that.showAll == true && that.clickDynamicCount == 0){
                    that.clickDynamicCount++;
                    //eslint-disable-next-line no-unused-vars
                    that.newApi.setDynamicClickCount({id:id}).then((res) => {})
                }
            },
        }
    }

</script>

<style scoped>
    .Dynamic{
        width: 100%;
        padding: 10px 38px 10px;
        box-sizing: border-box;
        margin-bottom: 10px;
        background-color: #ffffff;
        border-radius: 5px 5px 5px 5px;
        box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.1600);
        cursor: pointer;
    }
    .Dynamic .head{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .Dynamic .substance{
        line-height: 26px;
        font-size: 15px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .Dynamic .substance .contentType{
        color: #031F88;
        height: 16px;
        font-weight: 600;
        margin-right: 10px;
    }
    .Dynamic .substance .t{
        color: #555555;
    }
    .Dynamic .imgs{
        margin-top: 14px;
    }
    .spacebetween{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .volume{
        height: 30px;
        line-height: 30px;
        color: #999999;
        font-size: 12px;
    }
    .Dynamic .bottom{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        line-height:50px;
        height: 50px;
        color: #444444;
        font-size: 16px;
        text-align: center;
    }
    .Dynamic .bottom .btn{
        display: flex;
        justify-content:center;
        align-items: center;
        flex: 1;
    }
    .Dynamic .content {
        text-overflow:ellipsis;
        -webkit-line-clamp:4;
        -webkit-box-orient:vertical;
        overflow:hidden;
        display:-webkit-box;
        margin-bottom: 6px;
    }
    .Dynamic .content::before{
        content: '';
        float: right;
        width: 0;
        height: 78px;
    }
    .Dynamic .contentAll {
        overflow:hidden;
        margin-bottom: 12px;
    }
    .mr5{
        margin-right: 5px;
    }

    /deep/.event-form .el-dialog__header {
      padding: 0;
    }

    /deep/.event-form .el-dialog__body {
      padding: 0;
    }
</style>
